:root{
--boxShadow:0 0 5px black;
--textShadow: 0 0 3px black;
--btnBorderRadius:7px;
--borderRadius:7px;
--light:#444;
--dark:#000;
--primary:darkblue;
--secondary:dodgerblue;
--warning:darkorange;
--danger: red;
--success: green;
--slider3:3;
--slider5 :5;
--slider6 :6;
}

*,::before,::after{
margin:0;
padding:0;
box-sizing:border-box;
user-select:none;
}


html{
  scroll-behavior: smooth;
  transition:2s ease-out;
}

body{
overflow-x:hidden;
user-select:none;
overflow-y: scroll;
scroll-behavior: smooth;
transition:2 ease-out;
}

/*header
starts here
 styling*/
.header{
width:100%;
height:40px;
background:transparent;
text-align:center;
z-index:99999;
position:fixed;
line-height: 25px;
top:0px;
font-size: 20px;
overflow:hidden;
color:white;
box-shadow:var(--boxShadow);
}


.header-success{
color:white;
background:var(--success);
box-shadow:var(--boxShadow);
}

.header-primary{
color:white;
background:var(--primary);
box-shadow:var(--boxShadow);
}

.header-secondary{
color:white;
background:var(--secondary);
box-shadow:var(--boxShadow);
}

.header-danger{
color:white;
background:var(--danger);
box-shadow:var(--boxShadow);
}

.header-warning{
color:white;
background:var(--warning);
box-shadow:var(--boxShadow);
}

.header-light{
  color:white;
  background:var(--light);
  box-shadow:var(--boxShadow);
  }
  .header-dark{
    color:white;
    background:var(--dark);
    box-shadow:var(--boxShadow);
    }
/*header
ends here
 styling*/
 
 /*navb
 starts here
 styling*/

.navb{
width:100%;
height:60px;
background:rgba(0,0,0,0.1);
text-align:center;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
position:fixed;
margin-bottom:50px;
z-index:999;
top:25px;
padding-left:5px;
overflow:hidden;
padding-bottom:4px;

}

.navb a {
text-decoration:none;
font-size:18px;
z-index:99999;
color:black;
line-height:70px;
}

.navb:after{
content:"MENU";
position:fixed;
width:100%;
height:40px;
background:inherit;
box-shadow:var(--boxShadow);
left:0px;
top:0px;
line-height: 28px;
overflow-y: hidden;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}




.navb > a:hover a{
 background: white;
 color:black;
 transition:0.5s;
 border-radius: 3px;

}

.navb .bars:hover{
background: transparent;
color:white;
box-shadow:0px 0px 0px transparent;
transition:1s;
}


.navb >.bars{
font-size:25px;
left:30px;
position:fixed;
line-height:65px;
padding-right:60px;
font-weight:bold;
background:transparent;
z-index:99999;
}

/*.navb a.visited{
  background: white;
  color:black;
  transition:0.5s;
  border-radius: 3px;
  font-size: 20px;
}*/

.navb-success {
color:white;
background:var(--success);
}
.navb-success a{
color:white;
background:var(--success);
}

.navb-primary {
color:white;
background:var(--primary);
}
.navb-primary a{
color:white;
background:var(--primary);
}


.navb-secondary{
color:white;
background:var(--secondary);

}
.navb-secondary a{
color:white;
background:var(--secondary);

}

.navb-danger  {
color:white;
background:var(--danger);
}

.navb-danger  a{
color:white;
background:var(--danger);
}

.navb-warning  {
color:white;
background:var(--warning);
}
.navb-warning a {
color:white;
background:var(--warning);
}

.navb-light {
  color:white;
  background:var(--light);
  }
   
  .navb-light a{
    color:white;
    background:var(--light);
    }

  .navb-dark {
    color:white;
    background:var(--dark);
    }
      .navb-dark a {
        color:white;
        background:var(--dark);
        }

/*navb
ends here
styling*/

/*sidebar
starts here
styling*/

.sidebar{
width:0px;
height:190vh;
background:rgb(0,0,0,0.1);
text-align:center;
font-family: courier new;
position:fixed;
top:0px;
overflow-x:hidden;
overflow-y:auto;
transition: 0.5s;
z-index:999;
margin-bottom:50px;
color:white;
bottom:0vh;
padding-bottom:130px;
}





.sidebar a{
display:block;
line-height:50px;
text-decoration:none;
}

.sidebar a:hover a{
 background: white;
 color:black;
}
 .cross{
font-size: 35px;
margin-top:30px;
z-index:999;
}

.sidebar-success {
color:white;
background:var(--success);
}

.sidebar-success a {
color:white;
background:var(--success);
}

.sidebar-primary {
color:white;
background:var(--primary);
}

.sidebar-primary a{
color:white;
background:var(--primary);
}


.sidebar-secondary{
color:white;
background:var(--secondary);

}

.sidebar-secondary a{
color:white;
background:var(--secondary);

}

.sidebar-danger {
color:white;
background:var(--danger);

}

.sidebar-danger a{
color:white;
background:var(--danger);

}

.sidebar-warning{
color:white;
background:var(--warning);

}

.sidebar-warning a{
color:white;
background:var(--warning);

}

.sidebar-light{
  color:white;
  background:var(--light);
  
  }
  
  .sidebar-light a{
  color:white;
  background:var(--light);
  
  }

  .sidebar-dark{
    color:white;
    background:var(--dark);
    
    }
    
    .sidebar-dark a{
    color:white;
    background:var(--dark);
    
    }



/*sidebar
ends here
styling*/

/*button
starts here
styling*/

.btn{
width:90px;
height:30px;
border-radius:var(--btnBorderRadius);
margin-bottom: 10px;
margin-top: 15px;
border:none;
font-weight:bold;
cursor: pointer;
}


 .btn-primary{
background:var(--primary);
color:white;
}

.btn-secondary{
background:var(--secondary);
color:white;
}

.btn-success{
background:var(--success);
color:white;
}

.btn-warning{
background:var(--warning);
color:white;
}

.btn-danger{
background:var(--danger);
color:white;
}


.btn-light{
  background:var(--light);
  color:white;
  }

  .btn-dark{
    background:var(--dark);
    color:white;
    }

/*button
ends here
styling*/

/*container
ends here
styling*/


.container{
position:sticky;
text-align:center;
bottom: 0;
top: 18.5px;
margin-top:70px;
user-select:none;
overflow-x:hidden;
min-height:400px;
margin-bottom:150px;
padding-top: 20px;
width:100vw;
scroll-behavior: smooth;
transition:0.2 ease-out;
/*margin-left:1.25%;*/

}
.container > .flex-3{
  min-width:200px;
  margin-left: 50px;
}

.container h1, h2,h3 ,h4,h5,h6{
line-height:80px;
}

.container  div.flex{
width:20%;
height:100%;
float:left;
min-height:70%;
overflow:hidden;
margin-left:2.5%;
margin-right:2.5%;
margin-bottom:50px;
}

 
a{
text-decoration:none;

}






/*form style*/

.form{
width:30%;
display:flex;
height:100%;
box-shadow:var(--boxShadow);
border-radius:var(--borderRadius);
overflow:hidden;
margin-bottom:50px;
border-bottom: 1.5px solid;
margin-left:35%;
justify-content: center;
align-items: center;
}

.form input{
width:80%;
text-align:center;
margin-bottom:15px;
flex:1 0 100%;
outline:none;
border-top: none;
border-left: none;
border-right: none;
border-bottom: 3px solid;
background:transparent;
}




.form legend{
text-align:center;
margin-bottom:20px;
font-family:monospace;
margin-top:7px;
font-size: 16px;
}

.form-primary {
border: 2px solid var(--primary);
color: var(--primary);
}

.form-primary input {
border-bottom: 1.5px solid var(--primary);

}

.form-primary ::placeholder {
color: var(--primary);
}

.form-secondary{
border: 2px solid var(--secondary);
color: var(--secondary);
font-weight:bolder;
}

.form-secondary input  {
border-bottom: 1.5px solid var(--secondary);
}

.form-secondary ::placeholder {
color: var(--secondary);
font-weight:bolder;
}

.form-success{
border: 2px solid var(--success);
color: var(--success);
font-weight:bolder;
}

.form-success input {
border-bottom: 1.5px solid var(--success);
}

.form-success ::placeholder {
color: var(--success);
font-weight:bolder;
}

.form-warning{
border: 2px solid var(--warning);
color: var(--warning);
font-weight:bolder;
}

.form-warning input {
border-bottom: 1.5px solid var(--warning);
}

.form-warning ::placeholder {
color: var(--warning);
font-weight:bolder;
}


.form-danger{
border: 2px solid var(--danger);
color: var(--danger);
font-weight:bolder;
}

.form-danger input {
border-bottom: 1.5px solid var(--danger);
}

.form-danger ::placeholder {
color: var(--danger);
font-weight:bolder;
}



.form-light{
  border: 2px solid var(--light);
  color: var(--light);
  font-weight:bolder;
  }
  
  .form-light input {
  border-bottom: 1.5px solid var(--light);
  }
  
  .form-light ::placeholder {
  color: var(--light);
  font-weight:bolder;
  }

  
.form-dark{
  border: 2px solid var(--dark);
  color: var(--dark);
  font-weight:bolder;
  }
  
  .form-dark input {
  border-bottom: 1.5px solid var(--dark);
  }
  
  .form-dark ::placeholder {
  color: var(--dark);
  font-weight:bolder;
  }

  .form .textarea{
    outline :none;
    border: 1px solid inherit;
    width:125%;
    text-align: center;
    line-height: 22px;
    margin-left: -15px;
    
  }



/*end of form style*/


 .flex{
text-align:center;
line-height:22px;
grid-gap: 5px;
border-radius:var(--borderRadius);
}


.container .flex-3{
height: 100%;
text-align:left;
width:200px;
flex: 1 0 25%;
}



.card{
box-shadow : var(--boxShadow);
}


/*footer styling*/

.footer{
position:sticky;
width:100vw;
height: 300px;
font-family:sans-serif,arial,calibri,Times new roman;
box-shadow: var(--boxShadow);
background: black;
bottom:0vh;
margin-top: 80vh;
}



 .copywrite{
position:relative;
text-align:center;
top: 70px;
line-height:40px;
font-size: 17px;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}



.footer-primary{
background:var(--primary);
color:white;
}


.footer-primary a{
  background:var(--primary);
  color:white;
  }

.footer-secondary{
background:var(--secondary);
color:white;
}

.footer-secondary a{
  background:var(--secondary);
  color:white;
  }

  .footer-success {
    background:var(--success);
    color:white;
    }

.footer-success a{
background:var(--success);
color:white;
}

.footer-warning{
background:var(--warning);
color:white;
}

.footer-warning a{
  background:var(--warning);
  color:white;
  }

.footer-danger{
background:var(--danger);
color:white;
}

.footer-danger{
background:var(--danger);
color:white;
}

.footer-light{
  background:var(--light);
  color:white;
  }

  .footer-light a{
    background:var(--light);
    color:white;
    }
  .footer-dark{
    background:var(--dark);
    color:white;
    }

    .footer-dark a{
      background:var(--dark);
      color:white;
      }

/*end of footer*/

/*carousel styling*/
.caroussel{
display:grid;
grid-template-columns:repeat(6,1fr);
scroll-snap-type: x mandatory;
text-align:center;
line-height:200px;
margin-bottom: 100px;
margin-top:90px;
overflow:hidden;
float:left;
width:94.5%;
margin-left:2.5%;
margin-right:2.5%;
/*position: relative;
margin-left: 16%;*/

}



  .caroussel-items{
min-width:600px;
scroll-snap-align:start;
box-shadow:var(--boxShadow);
border-radius:var(--borderRadius);

}



.slider3{
animation:  carousel3 15s linear infinite;
}

.slider5{
animation:  carousel5 15s linear infinite;
}
 .slider6{
  float: left;
animation:  carousel6 15s linear infinite ;
}



@keyframes carousel3{
0%{
transform:translateX(0);
}

100%{
transform:translateX(calc((-600px)*(var(--slider3))*(0.65-0.15) )) ;
}

}


@keyframes carousel5{
0%{
transform:translateX(0);
}

100%{
transform:translateX(calc((-600px)*(var(--slider5))*(0.80-0.25) )) ;
}

}


@keyframes carousel6{
0%{
transform:translateX(0);

}


100%{

transform:translateX(calc((-600px)*(var(--slider6))*(0.85) )) ;
}

}





/*end of carousel*/

/*Table styling*/

.table{
  width:100%;
  font-family:Arial sans-serif;
  text-align: center;
}

.table-header{
  background:white;
  min-height:30px;
  min-width:100px;
  font-weight: bolder;
  font-size:16px;

}

 .table-header-primary{
  background:var(--primary);
  color:white;
}

 .table-header-secondary{
  background:var(--secondary);
  color:white;
}

 .table-header-success{
  background:var(--success);
  color:white;
}

 .table-header-warning{
  background:var(--warning);
  color:white;
}

 .table-header-danger{
  background:var(--danger);
  color:white;
}

.table-header-light{
  background:var(--light);
  color:white;
}


.table-header-dark{
  background:var(--dark);
  color:white;
}


.table,.table-header,.table-data{
  border-collapse: collapse;
}
.table-header ,.table-data{
  width:130px;
  }

.table-data{
min-width:50px;
font-size: 12px;
height:25px;
border-bottom:0.3px solid silver;
}

/*ends here*/


@media(max-width:600px){
.caroussel{
display:grid;
scroll-snap-type: x mandatory;
text-align:center;
line-height:200px;
margin-bottom: 100px;
margin-top:90px;
overflow:hidden;
max-width: 600px;
position: relative;
left: 0%;

}




.navb .bars{
display:block;
}
.navb > a{
display:none;
}


}



@media(max-width:768px){
 .caroussel{
display:grid;
scroll-snap-type: x mandatory;
text-align:center;
line-height:200px;
margin-bottom: 100px;
margin-top:90px;
overflow:hidden;
max-width: 600px;
position: relative;
left: 0%;


}




.navb .bars{
display:block;
}
.navb > a{
display:none;
}




.container  div.flex{
width:94.5%;
height:100%;
float:left;
min-height:70%;
overflow:hidden;
margin-bottom:50px;
margin-left:2.5%;
margin-right:2.5%;
}

.container  div.flex-2{
width:45%;
min-height:300px;
float:left;
min-height:70%;
overflow:hidden;
}


.footer{
font-size:13px;
}

.footer-text{
 position:relative;
 top: -25vh;
line-height: 20px;
margin-left: 2.5%;
font-size:13px;
}



.form{
  width:96%;
  display:flex;
  height:100%;
  box-shadow:var(--boxShadow);
  border-radius:var(--borderRadius);
 margin-bottom:50px;
  border-bottom: 1.5px solid;
  margin-left: 2%;
  }
  
  .form input{
  width:90%;
  text-align:center;
  margin-bottom:15px;
  flex:1 0 100%;
  outline:none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid inherit;
  background:transparent;
  }

  
  .form-secondary input{ border-bottom: 1px solid var(--secondary);}
  .form-primary input{ border-bottom: 1px var(--primary);}
  .form-warning input{ border-bottom: 1px var(--warning);}
  .form-success input{ border-bottom: 1px var(--success);}
  .form-danger input{ border-bottom: 1px var(--danger);}
  .form-light input{ border-bottom: 1px var(--light);}
  .form-dark input{ border-bottom: 1px var(--dark);}
  

}




@media(min-width:769px){


.container  div.flex{
width:25%;
height:100%;
float:left;
min-height:70%;
overflow:hidden;
margin-left:2.5%;
margin-right:2.5%;
margin-bottom:50px;
}


.container  div.flex-2{
  width:40%;
  height:100%;
  float:left;
  min-height:70%;
  overflow:hidden;
  margin-left:5%;
  margin-right:5%;
  margin-bottom:50px;
  }

.container  div.flex-3{
width:28%;
height:100%;
float:left;
min-height:70%;
overflow:hidden;
margin-left:3%;
margin-right:2%;
margin-bottom:50px;
}

.navb a{
margin-left:35px;
margin-right:35px;
}


.bars{
display:none;
}

.container{
  height:100%;
  overflow: hidden;
  width:100%;
}



}

