

.footer{
    height:150px;
}

.navb a.active{
  color:var(--secondary);
  background:white;
  font-size: 18px;
  border-radius: 5px;
}

.sidebar a.active{
  color:var(--secondary);
  background:white;
  font-size: 18px;
  border-radius: 5px;
}

.container.cust{
 
  background-color: black;
  height:100vh;
  width:100%;
}



 .image{
  position:sticky;
  left: 60%;
  height:100%;
}

.sentence{
  position:sticky;
  left: 25%;
}



.about{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
  font-weight: bolder;
  font-size: 14px;
  width:80%;
  margin-left: 10%;
  margin-right: 10%;
}




.aboutcontainer{
  border-radius: 5%;
  width:600px;
  text-align: center;

  color:black;
}

.profileInfo{
  margin-left: 25%;
}



.abouttitle{
  background: var(--secondary);
  color:white;
  max-height: 40px;
  font-size: 17px;
  font-weight: bolder;
  line-height: 28px;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 3px;
}

.currentskills{
  background: var(--secondary);
  color:white;
  max-height: 40px;
  font-size: 16px;
  font-weight: bolder;
  line-height: 28px;
}


.skills{
scroll-behavior: smooth;
transition:2s ease-out;
}

.frontendbox{
font-size:15px;
background: var(--secondary);
color:white;
text-align: center;
}

.backendbox{
  font-size:15px; 
  background: var(--secondary);
  color:white;
  text-align: center;

}

.frontend-items{
  display:none;
 opacity:0;
 font-size:13px; 
 color:white;
 font-weight: lighter;
 font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
 margin-bottom:0px;
 margin-top:0px;
 text-align: center;
 
}

.backend-items{
 display:none;
  opacity:0;
  font-size:13px; 
  color:white;
  font-weight: lighter;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-bottom:0px;
  margin-top:0px;
  text-align: center;
}

.frontend:hover ~ .frontend-items  {
  display: block;
  animation: front  2s ease-in-out 0s infinite;
}


.backend:hover ~ .backend-items  {
 display: block;
 animation: back  2s ease-in-out 0s infinite;

}


@keyframes back {
  0%{
    opacity:0.1;
  }
  
  50%{
   opacity:0.6;
  }
 
  100%{
   opacity:1;
  }


}


@keyframes front {
 0%{
   opacity:0.1;
 }
 
 50%{
  opacity:0.6;
 }

 100%{
  opacity:1;
 
 }

}



.thankscontainer{
  margin-left:10%;
  margin-right: 10%;
  background: var(--secondary);
  margin-top: 4%;
}

.thanks{
   color:white;
   font-size: 22px;

}

.redirect{
  margin-top: 600px;  
  text-decoration: none;
  color:black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: bolder;
  margin-top: 24%;
}





.lname{
margin-left: 2px;
}


.media{
  position: absolute;
  left:40%;
  margin:35px;
  
}



.media span{
  padding-left: 25px;
}



@media(max-width:768px){

 
 
 
 
.about{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
  font-weight: bolder;
  font-size: 14px;
  width:100%;
  margin-left: 0%;

}
 

  .media{
    position: absolute;
    left:8%;
    margin:35px;
    
  }
  
  
  
  .media span{
    padding-left: 25px;
  }
  


}





