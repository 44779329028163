:root{
  --bgSize:150px;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
    
   }
   
  


   h3{
      text-align:center;
      font: 24px  italic bold tahoma ;
   }
  
   
   .items{
    padding:55px;
  width : 125px;
  height : 350px;
  text-align:left;
  
   
  
  }
   
   .items a{
    text-decoration:none; 
    text-align: left; 
   }
   .content0{
     background: url(img/todo1.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
      
   }
   
   
   .content1{
     background: url(img/quiz.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
      
   }
   
   .content2{
     background: url(img/memory.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
      
   }
   
   .content3{
   background: url(img/color.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
      
   }
   
   .content4{
   background: url(img/tictactoe.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
   }
   
   
   .content5{
   background: url(img/racing1.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
   }
   
   
   .content6{
   background: url(img/snake.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
   }
   
   .content7{
   background: url(img/space2.png) no-repeat;
   background-size: calc(var(--bgSize)*1.2); 
    background-position:right;
   background-position-y: 10px;
   
   }
   
   
   .content8{
   background: url(img/rps1.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
   }
   
   
   .content9{
   background: url(img/cgjs.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
   }
   
   .content10{
   background: url(img/gdbjs.png) no-repeat;
   background-size:var(--bgSize);
    background-position:right;
   }
   
   .content11{
    background: url(img/swiper.png) no-repeat;
    background-size:var(--bgSize);
     background-position:right;
    }
   
    .content12{
      background: url(img/cui.png) no-repeat;
      background-size:var(--bgSize);
       background-position:right;
      }
      .content13{
        background: url(img/ajaxy.png) no-repeat;
        background-size:var(--bgSize);
         background-position:right;
        }

        .content14{
          background: url(img/tgb.png) no-repeat;
          background-size:var(--bgSize);
           background-position:right;
          }

        .content15{
            background: url(img/coffee15.jpg) no-repeat;
           
            background-size:var(--bgSize);
             background-position:right;
            }
  


   
   
   .items h3{
    text-align:left;
    font-size:19px;
    font-weight:bold;
    margin-top: -20px;
   }npm start
   
   .items p{
    text-align:left;
    font-size:15px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    margin-left: -30px;
  
   }
   
   .items strong{
     color:var(--warning);
   }

   .content7 h3{
   padding-top :0px;
 
 
   }
   
   .content7 p{
    padding-top:0px;
   text-align:left;
    min-height: 110px;
   }
   
   
   
   
   @media screen and (max-width:768px){
    .items{
   width : 120px;
   height : 250px;
   box-shadow: 0 0 10px black;
   text-align:center;
   flex: 1 0 50%;
    margin:5%;
    
    }
    .content0 , .content1,  .content2,.content3, .content4, .content5, .content6
    ,.content8, .content9, .content10, .content11,.content12, .content13,.content14,.content15{
      background-size:40%;
    }
    
    .items h3{
    text-align:left;
    font-size:16px;
   font-weight:bold;
   }
   
   .items p{
    text-align:left;
    font-size:14px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    margin-left: -25px;
  
   }
   
    .content7 h3{
    font-size:15px;
   text-align:left;
    color:black;
    font-family:helvetica;
    font-weight:bold;
    margin-left: -30px;
   }
   
   .content7 p{
   text-align:left;
    color:black;
    font-size:14px;
    margin-left: -30px;
   }
   
   strong{
   color:black;
   
   }
    
      
   }
   
   
  
   
   
   